<template>
    <div class="assessBox">
        <div class="app-container">
            <div class="titlebox">
                <el-button size="medium" type="text" @click="$router.go(-1)">返回</el-button>

                <div class="title">{{ title }}</div>
            </div>
            <div class="viewbox">
                <div>
                    <div style="width:100%" class="promotionInfo">
                        <div class="inputBox">
                            <div>
                                <label class="left_label">组织负责人</label>
                                <label class="right_label">
                                    <el-input disabled v-model="orgInfo.orgPrinName" style="width:70%">
                                    </el-input>
                                </label>
                            </div>
                            <div>
                                <label class="left_label" style="margin-right: 45px;">组织</label>
                                <label class="right_label">
                                    <el-input disabled v-model="orgInfo.orgName" style="width:70%">
                                    </el-input>
                                </label>
                            </div>
                        </div>
                        <div>
                            <div style="width:100%;padding:0px 35px;">
                                <el-tree :data="treeData" :props="defaultProps" show-checkbox node-key="id"
                                    :indent="indent" empty-text="暂无组织架构" default-expand-all
                                    :default-expanded-keys="defaultExpandedKeys"
                                    :default-checked-keys="defaultCheckedKeys" ref="orgTree">
                                </el-tree>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="viewFoot">
                <el-button size="medium" @click="$router.go(-1)">取消</el-button>
                <el-button type="primary" size="medium" @click="save">保存</el-button>
            </div>
        </div>
    </div>

</template>
<script>

import * as orgApi from '@/api/workbench';
export default {

    components: {

    },

    data() {
        return {
            indent: 30,
            defaultProps: {
                children: 'children',
                label: 'orgName'
            },
            treeData: [],
            defaultExpandedKeys: [],
            defaultCheckedKeys: [],
            orgInfo: {
                id: '',
                orgPid: '',
                orgPath: '',
                orgCode: '',
                orgName: '',
                orgType: '',
                orgPrinId: '',
                orgPrinName: '',
                cityName: '',
                remark: '',
                provinceCode: '',
                cityCode: '',
                directNumber: '',
                officialNumber: '',
                informalityNumber: '',
                countNumber: '',
                employeesNumber: '',
                monthEndNumber: '',
                children: []
            },
            title: '组织负责人权限控制'
        };

    },

    created() {
    },

    mounted() {
        this.getAllOrg();
        this.$nextTick(function () {
            this.orgInfo = this.$route.query.orgInfo;
            console.log('当前组织负责人信息', this.orgInfo);
            orgApi.getOrgPermissionList({ request: { staffId: this.orgInfo.orgPrinId } }).then((res) => {
                this.defaultExpandedKeys = this.defaultExpandedKeys.concat(res);
                this.defaultCheckedKeys = this.defaultCheckedKeys.concat(res);
            }).catch(() => { });
        });
    },
    methods: {
        getAllOrg() {
            orgApi.queryDepartment({ request: {} }).then((res) => {
                this.treeData = res;
            }).catch(() => { });
        },
        // 获取选中的node节点对象
        getCheckedNodes() {
            console.log('getCheckedNodes is ', this.$refs.orgTree.getCheckedNodes());
        },
        // 获取选中的node节点的key
        getCheckedKeys() {
            const nodeKeys = this.$refs.orgTree.getCheckedKeys();

            console.log('选中的node节点的key ', nodeKeys);
            return nodeKeys;
        },
        save() {
            if (this.orgInfo.orgPrinId == '') {
                this.$message.error('组织负责人ID为空，请返回上一页面重新进入！');
                return;
            }
            const keys = this.getCheckedKeys();

            const request = {
                orgIds: keys,
                staffId: this.orgInfo.orgPrinId
            };

            orgApi.saveOrgPermission({ request }).then((res) => {
                console.log('部门权限已更新！', res);
                this.$message.success('部门权限已更新！');
            }).catch((error) => {
                console.log('保存部门权限异常', error);
            });
        }
    }
};
</script>
<style lang="scss" scoped>
.assessBox .app-container .viewbox .level_box_now {
    font-weight: bold;
}

.picbox {
    padding: 0px 0 0 100px;
    background: #fff;
    margin: 0 20px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    height: 140px;

    :first-child {
        margin-right: 10px;
    }

    h2 {
        margin: 10px 0 0 0;
    }

    p {
        margin: 15px 0px;

        color: #999999;
    }
}

.assessBox {
    .app-container {
        color: #484848;
        padding: 0;
        background-color: #f6f7fb;

        .titlebox {
            text-align: center;
            line-height: 17px;
            background: #fff;
            font: bold 18px/25px "Microsoft YaHei";
            padding: 14px 40px;

            >.el-button {
                float: left;
                color: #484848;
                transform: translateY(-8px);
                font-size: 16px;
            }

            text-align: center;
            line-height: 17px;
            font: bold 18px/25px "Microsoft YaHei";
            margin: 20px;
        }

        .viewbox {
            margin: 0 20px;
            background: #ffffff;
            min-height: 300px;
            padding: 50px;

            .promotionInfo {
                text-align: center;
                font-size: 14px;

                .inputBox {
                    margin-bottom: 10px;

                    div {
                        width: calc(100% / 2);
                        display: inline-block;
                    }
                }

                .left_label {
                    margin-right: 30px;
                    color: #999999;
                    text-align: right;
                    width: 130px;
                    font-weight: normal;
                }

                .right_label {
                    width: 70%;
                }
            }
        }

        .viewFoot {
            text-align: center;

            .el-button {
                margin: 15px;
                padding: 10px 45px;
            }
        }
    }
}

.titleIcon {
    display: inline-block;

    width: 8px !important;

    height: 20px;

    background: #498df0;

    border-radius: 5px;

    vertical-align: bottom;

    margin-right: 5px !important;
}
</style>
<style lang="scss">
.promotion {
    .el-input {
        width: 100% !important;
    }
}

.jobSelect .el-input--suffix {
    width: 100% !important;
}
</style>
